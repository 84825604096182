
const ChatWindow = ()=>{

    return(
        <div className={`chat-window`}>
            <h1>Chat</h1>
        </div>
    )

}

export default ChatWindow